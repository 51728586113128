import * as Sentry from '@sentry/sveltekit';
import { version, dev as isLocal } from '$app/environment';

const SENTRY_DSN =
	'https://fbb88a9e0c264927aa124b0ebab13d78@o457413.ingest.sentry.io/6398589' as const;

type InitSentryOptions = Pick<
	Sentry.BrowserOptions,
	'ignoreErrors' | 'integrations' | 'denyUrls'
> & { isDevEnv: boolean };

export function initSentry(options: InitSentryOptions) {
	Sentry.init({
		dsn: SENTRY_DSN,

		// We want to disable logging if app is running on a dev env, locally, or in testing mode
		enabled: !options.isDevEnv && !isLocal && import.meta.env.MODE !== 'testing',

		integrations: options?.integrations,

		// More regarding filtering: https://docs.sentry.io/platforms/javascript/guides/sveltekit/configuration/filtering/
		ignoreErrors: options?.ignoreErrors,
		denyUrls: options?.denyUrls,

		release: version ?? 'Unknown',
	});
}
